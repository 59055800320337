<template>
  <section class="me">
    <div>
      <div class="box" :class="isEditing ? 'box-warning' : 'box-primary'">
        <template v-if="mode == 'editor'">
          <div class="box-header">
            <span
              :class="isEditing ? 'clicable-title' : ''"
              @click.stop.prevent="$emit('panelProperties')"
            >
              <i v-if="panel.icon" :class="panel.icon"></i>
              <span> {{ $t(panel.title) }} </span>
            </span>
            <slot name="toolbar"></slot>
          </div>
        </template>
        <div class="box-header with-border" v-else>
          <span class="box-title">
            <i v-if="panel.icon" :class="panel.icon"></i>
            <span> {{ $t(panel.title) }} </span>
          </span>
          <slot name="toolbar"></slot>
        </div>
        <div class="box-body">
          <iframe
            class="custom-widget"
            v-if="src"
            :src="src"
            :style="iframeStyle"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "EquipmentCustomPanel",
  props: {
    panel: {
      type: Object,
      required: false,
      default: () => null
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    src() {
      //return "http://localhost:8080/static/common/pages/mqtt-sim.html?connector_id=3201&app_static_dir=app.dev.telemetria.webhi.com.br";
      return this?.panel?.options.src || "";
    },
    iframeStyle() {
      let style = {
        "overflow-x": (this?.panel?.style || {})["overflow-x"],
        "overflow-y": (this?.panel?.style || {})["overflow-y"]
      };
      return style;
    }
  }
};
</script>

<style scoped>
.me {
  width: 100%;
  margin: 0;
  min-height: inherit;
  position: relative;
}

.me > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.box {
  height: 100%;
}
.box > .box-header {
  padding: 5px;
  min-height: 30px;
}
.box > .box-body {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0 0 30px 0;
}

iframe.custom-widget {
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
}
</style>
